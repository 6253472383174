import { combineReducers } from "redux";
import { boxesReducer } from "./boxes/boxesReducer";
import { churchesReducer } from "./boxes/churchesReducers";

const rootReducer = combineReducers({
	boxes: boxesReducer,
	churches: churchesReducer,
});

export default rootReducer;
