import { Table } from "reactstrap";

const RptBoxes = ({ dataBoxes = [] }) => {
	return (
		<Table responsive>
			<thead>
				<tr>
					<th>Género</th>
					<th>Edad</th>
					<th>Cantidad</th>
				</tr>
			</thead>
			<tbody>
				{dataBoxes.length > 0 &&
					dataBoxes.map(({ genre, age, qty }) => (
						<tr key={`${genre}-${age}`}>
							<td>{genre}</td>
							<td>{age}</td>
							<td>{qty}</td>
						</tr>
					))}
			</tbody>
		</Table>
	);
};

export default RptBoxes;
