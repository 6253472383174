import { Provider } from "react-redux";
import { Container } from "reactstrap";
import { AppProvider } from "./context/AppContext";
import { store } from "./redux/storeConfig/store";
import RegisterBox from "./RegisterBox";

export const RegisterApp = () => {
	return (
		<Provider store={store}>
			<AppProvider>
				<Container>
					<RegisterBox />
				</Container>
			</AppProvider>
		</Provider>
	);
};
