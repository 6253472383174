import { createContext } from "react";
import { fetchSinToken } from "../helpers/fetch";

export const AppContext = createContext();

// const initialState = {
// 	iglesia: "",
// 	role: "",
// 	nombre: "",
// 	email: "",
// 	telefono: "",
// 	modalidad: "",
// };

export const AppProvider = ({ children }) => {
	// const [app, setApp] = useState(initialState);
	const registerBox = async (bid, genre, age, qty) => {
		const resp = await fetchSinToken("boxes", { bid, genre, age, qty }, "POST");
		return resp;
	};

	const register = async (
		iglesia,
		role,
		nombre,
		email,
		telefono,
		modalidad,
	) => {
		const resp = await fetchSinToken(
			"maestros",
			{
				iglesia,
				role,
				nombre,
				email,
				telefono,
				modalidad,
			},
			"POST",
		);

		return resp;
	};

	const getCnModalidad = async () => {
		const resp = await fetchSinToken("modalidad");
		return resp;
	};

	return (
		<AppContext.Provider value={{ getCnModalidad, register, registerBox }}>
			{children}
		</AppContext.Provider>
	);
};
