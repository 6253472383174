import { boxesTypes } from "../../types/boxesTypes";

const initialState = {
	cajas: [],
	cajaSeleccionada: [],
	cajasAsignadas: [],
};

export const boxesReducer = (state = initialState, action) => {
	switch (action.type) {
		case boxesTypes.BOXES_LIST:
			return { ...state, cajas: action.payload };
		case boxesTypes.BOX_DETAILS:
			return {
				...state,
				cajaSeleccionada: action.payload,
			};
		case boxesTypes.BOXES_PROVISIONAL_ASSIGNED:
			return {
				...state,
				cajasAsignadas: [...state.cajasAsignadas, action.payload],
			};
		default:
			return state;
	}
};
