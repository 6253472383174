const baseUrl = process.env.REACT_APP_API_URL;

export const fetchSinToken = async (endpoint, data, method) => {
	const url = `${baseUrl}/${endpoint}`;

	try {
		if (method === "GET") {
			const resp = await fetch(url);
			return await resp.json();
		} else {
			const resp = await fetch(url, {
				method,
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});

			return await resp.json();
		}
	} catch (error) {
		console.log(error);
	}
};
