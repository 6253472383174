import { useContext, useState } from "react";
import { QrReader } from "react-qr-reader";
import QRScan from "qrscan";
import { Button, Container, Form, FormGroup, Input, Label } from "reactstrap";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { AppContext } from "./context/AppContext";
import RptBoxes from "./components/RptBoxes";

const RegisterBox = () => {
	const MySwal = withReactContent(Swal);
	const { registerBox } = useContext(AppContext);
	const [data, setData] = useState(false);
	const [genre, setGenre] = useState(null);
	const [age, setAge] = useState(null);
	const [enable, setEnable] = useState(false);

	const extractInfo = (text) => {
		let genre = text.substring(0, 1);
		let age = text.substring(1, 3);

		switch (age) {
			case "02":
				setAge(2);
				break;
			case "05":
				setAge(5);
				break;
			case "10":
				setAge(10);
				break;
		}

		genre === "B" ? setGenre("BOY") : setGenre("GIRL");
	};

	const getAgeRange = () => {
		switch (age) {
			case 2:
				return "2 - 4";
			case 5:
				return "5 - 9";
			case 10:
				return "10 - 14";
		}
	};

	const [showCamera, setShowCamera] = useState(false);
	const [total, setTotal] = useState({});

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEnable(true);

		if (qty === "" || qty < 0) {
			MySwal.fire("ONN", "Por favor digita la cantidad de OE.", "error");
			return;
		}
		let ddbbBoxes = JSON.parse(localStorage.getItem("ddbbBoxes"));

		let dataBox = {
			...ddbbBoxes,
			[data]: {
				genre,
				age,
				qty,
			},
		};

		const {
			ok,
			data: boxInfo,
			messages,
		} = await registerBox(data, genre, age, qty);

		if (messages?.error) {
			MySwal.fire("ONN", messages.error, "error");
			setForm({ qty: "" });
			setShowCamera(false);
			setData(null);
			setEnable(false);
			return;
		}

		const { box, total } = boxInfo;
		const { bid } = box;
		setTotal(total);

		localStorage.setItem("ddbbBoxes", JSON.stringify(dataBox));
		setForm({ qty: "" });
		setShowCamera(false);
		setData(false);
		MySwal.fire(
			"ONN",
			`Se ha registrado con éxito la caja ${bid}. POR FAVOR ESCRIBE ${qty} EN LA CAJA`,
			"success",
		);
		setEnable(false);
	};

	const [form, setForm] = useState({
		qty: "",
	});

	const { qty } = form;

	const onChange = ({ target }) => {
		const { name, value } = target;

		setForm({ ...form, [name]: value });
	};

	const [qrVal, setQrVal] = useState({ value: "", watching: false });
	const onFind = (value) => {
		setData(value);
		extractInfo(value);
		setQrVal({ value, watching: false });
	};
	const handleShowCamera = () => {
		setShowCamera(true);
		setQrVal((prev) => ({ ...prev, watching: true }));
	};

	return (
		<>
			<Container>
				{!showCamera && (
					<Button color="primary" onClick={handleShowCamera}>
						Registrar Caja
					</Button>
				)}
				{!data ? (
					showCamera && <QRScan onFind={onFind} />
				) : (
					<Form onSubmit={handleSubmit}>
						<FormGroup>
							<Label for="qty">
								Digita la cantidad de OE en la caja <b>{data}</b> que es de{" "}
								<b>{genre}</b> rango de edad <b>{getAgeRange()}</b>
							</Label>
							<Input
								id="qty"
								type="number"
								name="qty"
								value={qty}
								onChange={onChange}
								max={16}
								min={8}
							/>
						</FormGroup>
						<Button color="primary" disabled={enable}>
							Registrar
						</Button>
					</Form>
				)}
				<hr />
				<RptBoxes dataBoxes={total} />
			</Container>
		</>
	);
};

export default RegisterBox;
