import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";

import { RegisterApp } from "./RegisterApp";
import "./styles/styles.scss";
import RegisterBox from "./RegisterBox";

ReactDOM.render(<RegisterApp />, document.getElementById("root"));
