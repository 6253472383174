import { churchesTypes } from "../../types/churchesTypes";

const initialState = {
	iglesias: [],
	iglesiaSeleccionada: {},
};

export const churchesReducer = (state = initialState, action) => {
	switch (action.type) {
		case churchesTypes.CHURCHES_LIST:
			return {
				...state,
				iglesias: action.payload,
			};
		case churchesTypes.CHURCHES_SELECTED:
			return {
				...state,
				iglesiaSeleccionada: action.payload,
			};
		default:
			return state;
	}
};
